import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import SimilarCard from '../../../components/similar.card';
import SEO from '../../../components/seo';

import imgSEO from '../../../assets/img/services/site-vitrine.jpg';

import similar1 from '../../../assets/img/services/contenu.jpg';
import similar2 from '../../../assets/img/services/thumbnail/seo.jpg';
import similar3 from '../../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../../assets/img/services/developpement.jpg';
import cta from '../../../assets/img/illustrations/media_player.svg';
import CtaSection from '../../../components/cta.section';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Création de site vitrine | Agence digitale | MDS Digital Agency"
      description="Vous souhaitez assurer la visibilité de votre activité sur le web? Nous créons votre site vitrine personnalisé à l’image de votre société."
      keywords="site vitrine"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Site vitrine</h2>
      <p>
        Indépendant, commerçant, artisan ou entreprise, vous souhaitez assurer{' '}
        <strong>la visibilité de votre activité sur le web</strong>? Nous créons votre site vitrine
        personnalisé à l’image de votre société.
      </p>
      <p>
        Voici comment se passe la <strong>création de votre site étape par étape</strong>:
      </p>
      <ul>
        <li>Nous vous conseillons la meilleure solution adaptée à vos besoins.</li>
        <li>
          Nous portons notre attention sur les spécificités de votre secteur et sur les objectifs de
          votre présence sur le web.
        </li>
        <li>
          Votre logo, vos couleurs et les autres éléments graphiques qui définissent votre{' '}
          <AniLink cover bg="#fba515" to="/services/graphic-design/">
            identité visuelle
          </AniLink>{' '}
          sont intégrés au design du projet. Nous pouvons également vous aider à développer votre{' '}
          <AniLink cover bg="#fba515" to="/services/graphic-design/">
            identité visuelle
          </AniLink>
          .
        </li>
        <li>
          Si vous disposez de textes, nous les incluons dans votre site mais nous pouvons également
          rédiger du contenu pour vous (
          <AniLink cover bg="#fba515" to="/services/copywriting/">
            copy
          </AniLink>
          ) et nous charger de sa traduction.
        </li>
        <li>Nous vous proposons une maquette personnalisée répondant à vos exigences.</li>
        <li>
          Nous avançons ensemble étape par étape pour aboutir à un site internet vitrine qui reflète
          fidèlement votre société, organisation ou activité.
        </li>
        <li>
          Nous nous chargeons de la réservation du nom de domaine et de l’hébergement de votre site
          internet ainsi que de la création d’une adresse email personnalisée.
        </li>
      </ul>

      <CtaSection
        img={cta}
        title="Optez pour un site internet de qualité, sur mesure et performant sans plus attendre."
        ctaText="Devis en ligne"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar1}
          />
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar2}
          />
          <SimilarCard
            text="Référencement naturel (SEA)"
            url="/services/referencement/payant/"
            img={similar3}
          />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
